import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import AnnouncementSmall from '../components/announcement-small'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ButtonSecondary from '../components/button-secondary'
import ContentNumber from '../components/content-number'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import SubtextProductPromo from '../components/subtext-product-promo'
import CardBlueprint from '../components/card-blueprint'
import ButtonSmallMain from '../components/button-small-main'
import CardCaseStudy from '../components/card-case-study'
import Testimonial from '../components/testimonial'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container10">
      <Helmet>
        <title>Gateway.fm - We Build Your Web3 Infrastructure</title>
        <meta
          name="description"
          content="We pioneer Web3 infrastructure to empower builders and organisations alike to harvest paramount utilities of blockchain technology."
        />
        <meta
          property="og:title"
          content="Gateway.fm - We Build Your Web3 Infrastructure"
        />
        <meta
          property="og:description"
          content="We pioneer Web3 infrastructure to empower builders and organisations alike to harvest paramount utilities of blockchain technology."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/00de1a57-4e2f-40dc-b72c-3d95157eb81e?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="home-video-container1">
        <video
          src="/external/gw-background-1.mp4"
          loop="true"
          muted="true"
          poster="/external/poster-hero-video-1500w.png"
          preload="auto"
          autoPlay="true"
          playsInline="true"
          className="home-video1"
        ></video>
        <div className="home-hero">
          <Navigation></Navigation>
          <div className="home-container11">
            <Link
              to="/blog/gateway-wirex-case-study"
              className="home-navlink10"
            >
              <AnnouncementSmall
                text="Crypto-financial transformation with Wirex"
                className="home-component11"
              ></AnnouncementSmall>
            </Link>
            <div className="home-description">
              <div className="home-title1">
                <div className="home-container12">
                  <span className="home-text10">
                    <span>YOUR BLOCKCHAIN</span>
                    <br></br>
                    <span>IN 6 MINUTES</span>
                  </span>
                </div>
                <div className="home-container13">
                  <span className="home-text14">
                    <span>Infrastructure for your project. </span>
                    <span>Testnets start at $999/month</span>
                  </span>
                </div>
              </div>
              <div className="home-buttons"></div>
            </div>
            <img
              alt="image"
              src="/external/brand-mascot-700h.png"
              className="home-image1"
            />
            <div className="home-container14">
              <a href="https://presto.gateway.fm/" className="home-link1">
                <ButtonPrimarySubtitle
                  main="Deploy blockchain"
                  supportive="Free trial, no credit card required"
                  rootClassName="button-primary-subtitleroot-class-name2"
                  className="home-component12"
                ></ButtonPrimarySubtitle>
              </a>
              <a href="#blueprints" className="home-link2">
                <ButtonSecondary
                  subtext="DEX, NFT platform, Digital bank &amp; more"
                  rootClassName="button-secondaryroot-class-name1"
                  className="home-component13"
                ></ButtonSecondary>
              </a>
            </div>
          </div>
          <div className="home-gradient"></div>
        </div>
      </div>
      <div className="home-logo-container">
        <div className="home-logo-line">
          <img
            alt="logooptimism7462"
            src="/external/logooptimism7462-cust-200h.png"
            className="home-logooptimism1"
          />
          <img
            alt="logocelestia7462"
            src="/external/logocelestia7462-x7i-200h.png"
            className="home-logocelestia1"
          />
          <img
            alt="logopolygon7462"
            src="/external/logopolygon7462-h4w-200h.png"
            className="home-logopolygon1"
          />
          <img
            alt="logosub77462"
            src="/external/logosub77462-wcr-200h.png"
            className="home-logosub71"
          />
          <img
            alt="logoradius7462"
            src="/external/logoradius7462-8j9q-200h.png"
            className="home-logoradius1"
          />
          <img
            alt="logonear7462"
            src="/external/logonear7462-ju1b-200h.png"
            className="home-logonear1"
          />
          <img
            alt="logoamlbot7462"
            src="/external/logoamlbot7462-pop-200h.png"
            className="home-logoamlbot1"
          />
          <img
            alt="logozkevm7462"
            src="/external/logozkevm7462-xgsp-200h.png"
            className="home-logozkevm1"
          />
          <img
            alt="logodora7462"
            src="/external/logodora7462-njfg-200h.png"
            className="home-logodora1"
          />
          <img
            alt="logosafe7462"
            src="/external/logosafe7462-aya7-200h.png"
            className="home-logosafe1"
          />
          <img
            alt="logooptimism7462"
            src="/external/logooptimism7462-cust-200h.png"
            className="home-logooptimism2"
          />
          <img
            alt="logocelestia7462"
            src="/external/logocelestia7462-x7i-200h.png"
            className="home-logocelestia2"
          />
          <img
            alt="logopolygon7462"
            src="/external/logopolygon7462-h4w-200h.png"
            className="home-logopolygon2"
          />
          <img
            alt="logosub77462"
            src="/external/logosub77462-wcr-200h.png"
            className="home-logosub72"
          />
          <img
            alt="logoradius7462"
            src="/external/logoradius7462-8j9q-200h.png"
            className="home-logoradius2"
          />
          <img
            alt="logonear7462"
            src="/external/logonear7462-ju1b-200h.png"
            className="home-logonear2"
          />
          <img
            alt="logoamlbot7462"
            src="/external/logoamlbot7462-pop-200h.png"
            className="home-logoamlbot2"
          />
          <img
            alt="logozkevm7462"
            src="/external/logozkevm7462-xgsp-200h.png"
            className="home-logozkevm2"
          />
          <img
            alt="logodora7462"
            src="/external/logodora7462-njfg-200h.png"
            className="home-logodora2"
          />
          <img
            alt="logosafe7462"
            src="/external/logosafe7462-aya7-200h.png"
            className="home-logosafe2"
          />
          <img
            alt="logooptimism7462"
            src="/external/logooptimism7462-cust-200h.png"
            className="home-logooptimism3"
          />
          <img
            alt="logocelestia7462"
            src="/external/logocelestia7462-x7i-200h.png"
            className="home-logocelestia3"
          />
          <img
            alt="logopolygon7462"
            src="/external/logopolygon7462-h4w-200h.png"
            className="home-logopolygon3"
          />
          <img
            alt="logosub77462"
            src="/external/logosub77462-wcr-200h.png"
            className="home-logosub73"
          />
          <img
            alt="logoradius7462"
            src="/external/logoradius7462-8j9q-200h.png"
            className="home-logoradius3"
          />
          <img
            alt="logonear7462"
            src="/external/logonear7462-ju1b-200h.png"
            className="home-logonear3"
          />
          <img
            alt="logoamlbot7462"
            src="/external/logoamlbot7462-pop-200h.png"
            className="home-logoamlbot3"
          />
          <img
            alt="logozkevm7462"
            src="/external/logozkevm7462-xgsp-200h.png"
            className="home-logozkevm3"
          />
          <img
            alt="logodora7462"
            src="/external/logodora7462-njfg-200h.png"
            className="home-logodora3"
          />
          <img
            alt="logosafe7462"
            src="/external/logosafe7462-aya7-200h.png"
            className="home-logosafe3"
          />
          <img
            alt="logooptimism7462"
            src="/external/logooptimism7462-cust-200h.png"
            className="home-logooptimism4"
          />
          <img
            alt="logocelestia7462"
            src="/external/logocelestia7462-x7i-200h.png"
            className="home-logocelestia4"
          />
          <img
            alt="logopolygon7462"
            src="/external/logopolygon7462-h4w-200h.png"
            className="home-logopolygon4"
          />
          <img
            alt="logosub77462"
            src="/external/logosub77462-wcr-200h.png"
            className="home-logosub74"
          />
          <img
            alt="logoradius7462"
            src="/external/logoradius7462-8j9q-200h.png"
            className="home-logoradius4"
          />
          <img
            alt="logonear7462"
            src="/external/logonear7462-ju1b-200h.png"
            className="home-logonear4"
          />
          <img
            alt="logoamlbot7462"
            src="/external/logoamlbot7462-pop-200h.png"
            className="home-logoamlbot4"
          />
          <img
            alt="logozkevm7462"
            src="/external/logozkevm7462-xgsp-200h.png"
            className="home-logozkevm4"
          />
          <img
            alt="logodora7462"
            src="/external/logodora7462-njfg-200h.png"
            className="home-logodora4"
          />
          <img
            alt="logosafe7462"
            src="/external/logosafe7462-aya7-200h.png"
            className="home-logosafe4"
          />
          <img
            alt="logooptimism7462"
            src="/external/logooptimism7462-cust-200h.png"
            className="home-logooptimism5"
          />
          <img
            alt="logocelestia7462"
            src="/external/logocelestia7462-x7i-200h.png"
            className="home-logocelestia5"
          />
          <img
            alt="logopolygon7462"
            src="/external/logopolygon7462-h4w-200h.png"
            className="home-logopolygon5"
          />
          <img
            alt="logosub77462"
            src="/external/logosub77462-wcr-200h.png"
            className="home-logosub75"
          />
          <img
            alt="logoradius7462"
            src="/external/logoradius7462-8j9q-200h.png"
            className="home-logoradius5"
          />
          <img
            alt="logonear7462"
            src="/external/logonear7462-ju1b-200h.png"
            className="home-logonear5"
          />
          <img
            alt="logoamlbot7462"
            src="/external/logoamlbot7462-pop-200h.png"
            className="home-logoamlbot5"
          />
          <img
            alt="logozkevm7462"
            src="/external/logozkevm7462-xgsp-200h.png"
            className="home-logozkevm5"
          />
          <img
            alt="logodora7462"
            src="/external/logodora7462-njfg-200h.png"
            className="home-logodora5"
          />
          <img
            alt="logosafe7462"
            src="/external/logosafe7462-aya7-200h.png"
            className="home-logosafe5"
          />
        </div>
      </div>
      <div className="home-content1">
        <div className="home-container15">
          <div className="home-container16">
            <div className="home-content2">
              <ContentNumber number="2’636"></ContentNumber>
              <ContentNumber
                text="Service Level Agreement"
                number="99.9%"
              ></ContentNumber>
              <ContentNumber text="Customisations" number="35+"></ContentNumber>
            </div>
          </div>
        </div>
        <SubtitleProductPromo text="PRESTO. CODE-FREE"></SubtitleProductPromo>
        <SubtextProductPromo text="Simply hit deploy button, configure your chain and start using it in less than 6 minutes"></SubtextProductPromo>
        <a href="https://presto.gateway.fm/" className="home-link3">
          <ButtonPrimarySubtitle
            main="Deploy blockchain"
            supportive="Free trial, no credit card required"
            rootClassName="button-primary-subtitleroot-class-name3"
            className="home-component19"
          ></ButtonPrimarySubtitle>
        </a>
        <div className="home-video2">
          <div id="playButton" className="home-play1">
            <img
              alt="play7309"
              src="/external/play7309-gpk.svg"
              className="home-play2"
            />
            <span className="home-text17">0:59</span>
          </div>
          <div id="video-preview" className="home-video-container2">
            <video
              id="videoItself"
              src="/external/presto-walkthrough-preview1.mp4"
              loop="true"
              muted="true"
              poster="/external/poster-frame1-200h.png"
              preload="auto"
              autoPlay="true"
              playsInline="true"
              className="home-video3"
            ></video>
          </div>
        </div>
        <div id="blueprints" className="home-container17">
          <SubtitleProductPromo text="BLUEPRINTS"></SubtitleProductPromo>
          <SubtextProductPromo></SubtextProductPromo>
          <div className="home-container18">
            <Link
              to="/web3-infrastructure-blueprint-digital-banking"
              className="home-navlink11"
            >
              <CardBlueprint
                image="/external/digital-banking-200h.png"
                className="home-component22"
              ></CardBlueprint>
            </Link>
            <Link
              to="/web3-infrastructure-blueprint-supply-chain"
              className="home-navlink12"
            >
              <CardBlueprint
                text="Enhance supply chain transparency, traceability, and efficiency"
                usp1="End-to-end product traceability"
                usp2="Facilitate trusted data sharing"
                usp3="No code. Zero knowledge"
                image="/external/blueprints/cargo-200h.png"
                subtext="Supply chain"
                className="home-component23"
              ></CardBlueprint>
            </Link>
            <Link
              to="/web3-infrastructure-blueprint-carbon-credits"
              className="home-navlink13"
            >
              <CardBlueprint
                text="Tokenize and trade carbon credits on a transparent and immutable ledger"
                usp1="Enable trusted carbon accounting"
                usp2="Facilitate liquid carbon markets"
                usp3="No code. Zero knowledge"
                image="/external/blueprints/leaf-200h.png"
                subtext="Carbon credits"
                className="home-component24"
              ></CardBlueprint>
            </Link>
          </div>
          <div id="hidden-blueprints" className="home-container19">
            <div className="home-container20">
              <Link
                to="/web3-infrastructure-blueprint-accounting-ledger"
                className="home-navlink14"
              >
                <CardBlueprint
                  text="Distributed ledger for transparent and auditable financial records"
                  usp1="Ensure data integrity and auditability"
                  usp2="Streamline reconciliation processes"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/accountingledger-200h.png"
                  subtext="Accounting ledger"
                  className="home-component25"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-decentralised-exchange"
                className="home-navlink15"
              >
                <CardBlueprint
                  text="Facilitate peer-to-peer trading of digital assets without intermediaries"
                  usp1="Enable trustless trading"
                  usp2="Maintain user control and custody"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/dex-200h.png"
                  subtext="DEX"
                  className="home-component26"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-digital-twins"
                className="home-navlink16"
              >
                <CardBlueprint
                  text="Create virtual replicas of physical assets, processes, or systems"
                  usp1="Enhance operational efficiency"
                  usp2="Enable predictive maintenance"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/digitaltwin-200h.png"
                  subtext="Digital twins"
                  className="home-component27"
                ></CardBlueprint>
              </Link>
            </div>
            <div className="home-container21">
              <Link
                to="/web3-infrastructure-blueprint-loyalty-program"
                className="home-navlink17"
              >
                <CardBlueprint
                  text="Reward customer loyalty with tokenized incentives and rewards"
                  usp1="True ownership and portability"
                  usp2="Composable, interoperable rewards"
                  image="/external/blueprints/loyaltyprogram-200h.png"
                  subtext="Loyalty program"
                  className="home-component28"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-viral-digital-asset"
                className="home-navlink18"
              >
                <CardBlueprint
                  text="Create and trade digital assets representing iconic moments"
                  usp1="Tokenize viral content"
                  usp2="Enable digital ownership and scarcity"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/memecoin-200h.png"
                  subtext="Viral digital asset"
                  className="home-component29"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-nft-platform"
                className="home-navlink19"
              >
                <CardBlueprint
                  text="Deploy your own NFT platform in 3 months"
                  usp1="Low-cost minting"
                  usp2="Seamless Web3 Integration"
                  usp3="White-Label NFT Marketplaces"
                  image="/external/artobject17309-zntb-200h.png"
                  subtext="NFT Platform"
                  className="home-component30"
                ></CardBlueprint>
              </Link>
            </div>
            <div className="home-container22">
              <Link
                to="/web3-infrastructure-blueprint-nft-gallery"
                className="home-navlink20"
              >
                <CardBlueprint
                  text="Showcase and trade unique digital art and collectibles as non-fungible tokens"
                  usp1="Enable digital scarcity and provenance"
                  usp2="Foster new art ecosystems"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/nftgallery-200h.png"
                  subtext="NFT Gallery"
                  rootClassName="card-blueprintroot-class-name"
                  className="home-component31"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-dao"
                className="home-navlink21"
              >
                <CardBlueprint
                  text="Manage organisations, grants, investments by your hive-mind"
                  usp1="Enable trustless trading"
                  usp2="Maintain user control and custody"
                  usp3="No code. Zero knowledge"
                  image="/external/hive26986-lpud-200h.png"
                  subtext="DAO"
                  rootClassName="card-blueprintroot-class-name1"
                  className="home-component32"
                ></CardBlueprint>
              </Link>
            </div>
          </div>
          <ButtonSmallMain
            divID="toggleButton"
            label="Show more"
            rootClassName="button-small-mainroot-class-name"
          ></ButtonSmallMain>
        </div>
        <SubtitleProductPromo text="Case studies"></SubtitleProductPromo>
        <div className="home-container23">
          <CardCaseStudy subtext="Private roll up for Gnosis Pay: 3rd party services and smart contracts"></CardCaseStudy>
          <CardCaseStudy
            icon="/external/vector1317-f6xm.svg"
            label="Wirex"
            subtext="Payment processing architecture on private zkEVM roll up, scaling"
          ></CardCaseStudy>
          <CardCaseStudy
            icon="/external/humanity7462-u66o.svg"
            label="Humanity protocol"
            subtext="Development of custom roll up to handle proof of humanity verification"
          ></CardCaseStudy>
        </div>
        <div className="home-container24">
          <CardCaseStudy
            icon="/external/palmnft12203-a4os-200h.png"
            label="PalmNFT"
            subtext="Seamless migration from Hyper Ledger Besu to Polygon in 250ms"
          ></CardCaseStudy>
          <CardCaseStudy
            icon="/external/challenge12203-n005-200h.png"
            label="Challenge"
            subtext="Private high performance roll up for games tourmaments"
          ></CardCaseStudy>
          <CardCaseStudy
            icon="/external/witness-chain-logo-200h.png"
            label="Witness Chain"
            subtext="Unifyed DePIN economies, physical asset-backed applications"
          ></CardCaseStudy>
        </div>
        <SubtitleProductPromo text="INTEGRATIONS"></SubtitleProductPromo>
        <div className="home-container25">
          <div className="home-container26">
            <div className="home-content3">
              <div className="home-frame481909">
                <span className="home-text18">Software stack</span>
                <div className="home-frame48190210">
                  <div className="home-frame48190010">
                    <img
                      alt="logopolygon7301"
                      src="/external/logos/logo-polygon1-200h.png"
                      className="home-logopolygon6"
                    />
                    <span className="home-text19">Polygon</span>
                  </div>
                  <div className="home-frame48190110">
                    <img
                      alt="logooptimism7301"
                      src="/external/logos/logo-optimism1-200h.png"
                      className="home-logooptimism6"
                    />
                    <span className="home-text20">Optimism</span>
                  </div>
                  <div className="home-frame48190111">
                    <img
                      alt="logooptimism7301"
                      src="/external/logos/logo-zk-sync-200h.png"
                      className="home-logooptimism7"
                    />
                    <span className="home-text21">zkSync</span>
                  </div>
                  <div className="home-frame48190112">
                    <img
                      alt="logooptimism7301"
                      src="/external/logos/logo-arbitrum-200h.png"
                      className="home-logooptimism8"
                    />
                    <span className="home-text22">Arbitrum</span>
                  </div>
                  <div className="home-frame48190113">
                    <img
                      alt="logooptimism7301"
                      src="/external/logos/logo-immutable-200h.png"
                      className="home-logooptimism9"
                    />
                    <span className="home-text23">Immutable</span>
                  </div>
                </div>
              </div>
              <div className="home-frame481906">
                <span className="home-text24">Data availability</span>
                <div className="home-frame48190211">
                  <div className="home-frame48190011">
                    <img
                      alt="logonear7301"
                      src="/external/logos/logo-near1-200h.png"
                      className="home-logonear6"
                    />
                    <span className="home-text25">NEAR</span>
                  </div>
                  <div className="home-frame48190114">
                    <img
                      alt="logocelestia7301"
                      src="/external/logos/logo-celestia1-200h.png"
                      className="home-logocelestia6"
                    />
                    <span className="home-text26">Celestia</span>
                  </div>
                  <div className="home-frame48190115">
                    <img
                      alt="availlogo"
                      src="/external/logos/avail_logo-200h.png"
                      className="home-logocelestia7"
                    />
                    <span className="home-text27">Avail</span>
                  </div>
                  <div className="home-frame4819031">
                    <div className="home-logodomicon">
                      <img
                        alt="domicon17301"
                        src="/external/domicon17301-cqfq-200h.png"
                        className="home-domicon1"
                      />
                    </div>
                    <span className="home-text28">Domicon</span>
                  </div>
                </div>
              </div>
              <div className="home-frame481908">
                <span className="home-text29">KYC/KYB</span>
                <div className="home-frame48190212">
                  <div className="home-frame48190012">
                    <img
                      alt="logoamlbot7301"
                      src="/external/logos/logo-aml-bot1-200h.png"
                      className="home-logoamlbot6"
                    />
                    <span className="home-text30">AMLBot</span>
                  </div>
                  <div className="home-frame48190116">
                    <div className="home-logogatenox">
                      <img
                        alt="z50vLHlz400x400217301"
                        src="/external/z50vlhlz400x400217301-nwvr-200h.png"
                        className="home-z50v-l-hlz400x40021"
                      />
                    </div>
                    <span className="home-text31">Gatenox</span>
                  </div>
                </div>
              </div>
              <div className="home-frame481911">
                <span className="home-text32">Account abstraction</span>
                <div className="home-container27">
                  <div className="home-frame48190213">
                    <div className="home-frame48190013">
                      <img
                        alt="logosafe7301"
                        src="/external/logos/logo-safe1-200h.png"
                        className="home-logosafe6"
                      />
                      <span className="home-text33">Safe</span>
                    </div>
                    <div className="home-frame48190117">
                      <img
                        alt="logobiconomy7301"
                        src="/external/logos/logo-biconomy-200h.png"
                        className="home-logobiconomy"
                      />
                      <span className="home-text34">Biconomy</span>
                    </div>
                    <div className="home-frame48190214">
                      <img
                        alt="logoden7301"
                        src="/external/logos/logo-den-200h.png"
                        className="home-logoden1"
                      />
                      <span className="home-text35">Den</span>
                    </div>
                    <div className="home-frame4819032">
                      <img
                        alt="logoden7301"
                        src="/external/logos/logo_lumex-200w.png"
                        className="home-logoden2"
                      />
                      <span className="home-text36">Lumx</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-frame48190014">
                <span className="home-text37">Block explorers</span>
                <div className="home-container28">
                  <div className="home-frame48190215">
                    <div className="home-frame48190015">
                      <img
                        alt="logoblockscout7301"
                        src="/external/logos/logo-blockscout-200h.png"
                        className="home-logoblockscout"
                      />
                      <span className="home-text38">Blockscout</span>
                    </div>
                    <div className="home-frame48190118">
                      <img
                        alt="logodora7301"
                        src="/external/logos/logo-dora1-200h.png"
                        className="home-logodora6"
                      />
                      <span className="home-text39">Dora</span>
                    </div>
                    <div className="home-frame48190119">
                      <img
                        alt="logodora7301"
                        src="/external/logos/okx-logo-200w.png"
                        className="home-logodora7"
                      />
                      <span className="home-text40">OKX Explorer</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-frame481905">
                <span className="home-text41">Infrastructure</span>
                <div className="home-frame48190216">
                  <div className="home-frame48190120">
                    <img
                      alt="logogevulot7301"
                      src="/external/logos/logo-btq-zk-proofs-200h.png"
                      className="home-logogevulot1"
                    />
                    <span className="home-text42">BTQ</span>
                  </div>
                  <div className="home-frame48190121">
                    <img
                      alt="logogevulot7301"
                      src="/external/logos/logo-gevulot-200h.png"
                      className="home-logogevulot2"
                    />
                    <span className="home-text43">Gevulot</span>
                  </div>
                </div>
              </div>
              <div className="home-frame48190122">
                <span className="home-text44">Oracles</span>
                <div className="home-frame48190217">
                  <div className="home-frame48190016">
                    <img
                      alt="logoredstone7309"
                      src="/external/logos/logo-redstone-200h.png"
                      className="home-logoredstone1"
                    />
                    <span className="home-text45">RedStone</span>
                  </div>
                  <div className="home-frame48190017">
                    <img
                      alt="logoredstone7309"
                      src="/external/logos/logo-api3-200h.png"
                      className="home-logoredstone2"
                    />
                    <span className="home-text46">API3</span>
                  </div>
                </div>
              </div>
              <div className="home-frame48190218">
                <span className="home-text47">Bridges</span>
                <div className="home-frame48190219">
                  <div className="home-frame48190018">
                    <img
                      alt="logoaxelar7301"
                      src="/external/logos/logo-axelar-200h.png"
                      className="home-logoaxelar"
                    />
                    <span className="home-text48">Axelar</span>
                  </div>
                  <div className="home-frame48190123">
                    <img
                      alt="logozkevm7301"
                      src="/external/logos/logo-zkevm1-200h.png"
                      className="home-logozkevm6"
                    />
                    <span className="home-text49">Native zkEVM</span>
                  </div>
                </div>
              </div>
              <div className="home-frame481912">
                <span className="home-text50">Security</span>
                <div className="home-frame48190220">
                  <div className="home-frame48190019">
                    <div className="home-logosub76">
                      <div className="home-maskgroup">
                        <img
                          alt="QIxcOqYERmwC85RhvBu4yYHYOU17301"
                          src="/external/logos/logo-sub71-200h.png"
                          className="home-q-ixc-oq-ye-rmw-c85-rhv-bu4y-yhyou1"
                        />
                      </div>
                    </div>
                    <span className="home-text51">Sub7</span>
                  </div>
                  <div className="home-frame48190124">
                    <img
                      alt="logoquantstamp7301"
                      src="/external/logos/logo-quantstamp-200h.png"
                      className="home-logoquantstamp"
                    />
                    <span className="home-text52">Quantstamp</span>
                  </div>
                  <div className="home-frame48190221">
                    <img
                      alt="logooxorio7301"
                      src="/external/logos/logo-oxorio-200h.png"
                      className="home-logooxorio"
                    />
                    <span className="home-text53">Oxorio</span>
                  </div>
                  <div className="home-frame4819033">
                    <img
                      alt="logoshieldifysecurity7301"
                      src="/external/logos/logo-shieldify-security-200h.png"
                      className="home-logoshieldifysecurity"
                    />
                    <span className="home-text54">Shieldify</span>
                  </div>
                </div>
              </div>
              <div className="home-frame4819034">
                <span className="home-text55">Data centers</span>
                <div className="home-frame48190222">
                  <div className="home-frame48190020">
                    <img
                      alt="logoaws7301"
                      src="/external/logos/logo-aws-200h.png"
                      className="home-logoaws"
                    />
                    <span className="home-text56">AWS</span>
                  </div>
                  <div className="home-frame48190125">
                    <img
                      alt="logogooglecloudgcp7301"
                      src="/external/logos/logo-google-cloud-gcp-200h.png"
                      className="home-logogooglecloudgcp"
                    />
                    <span className="home-text57">GCP</span>
                  </div>
                  <div className="home-frame48190223">
                    <img
                      alt="logomicrosoftazure7301"
                      src="/external/logos/logo-microsoft-azure-200h.png"
                      className="home-logomicrosoftazure"
                    />
                    <span className="home-text58">Azure</span>
                  </div>
                  <div className="home-frame4819035">
                    <img
                      alt="logobaremetal7301"
                      src="/external/logos/logo-bare-metal-200h.png"
                      className="home-logobaremetal"
                    />
                    <span className="home-text59">Bare Metal</span>
                  </div>
                </div>
              </div>
              <div className="home-frame481899">
                <span className="home-text60">Indexers</span>
                <div className="home-frame48190224">
                  <div className="home-frame48190126">
                    <img
                      alt="logogoldsky7301"
                      src="/external/logos/logo-goldsky1-200h.png"
                      className="home-logogoldsky1"
                    />
                    <span className="home-text61">Goldsky</span>
                  </div>
                  <div className="home-frame48190127">
                    <img
                      alt="logogoldsky7301"
                      src="/external/logos/logo-the-graph-200h.png"
                      className="home-logogoldsky2"
                    />
                    <span className="home-text62">The Graph</span>
                  </div>
                </div>
              </div>
              <div className="home-frame481907">
                <span className="home-text63">Wallets</span>
                <div className="home-frame48190225">
                  <div className="home-frame48190021">
                    <img
                      alt="logomagiclink7301"
                      src="/external/logos/logo-magic-link-200h.png"
                      className="home-logomagiclink"
                    />
                    <span className="home-text64">Magic link</span>
                  </div>
                  <div className="home-frame48190226">
                    <img
                      alt="logometamask7301"
                      src="/external/logos/logo-metamask-200h.png"
                      className="home-logometamask"
                    />
                    <span className="home-text65">MetaMask</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-container29">
          <div className="home-content4">
            <div className="home-title2">
              <span className="home-text66">
                <span>NODE AS A</span>
                <br></br>
                <span>SERVICE</span>
              </span>
            </div>
            <span className="home-text70">
              <span>Innovative solution to fundrase without</span>
              <br></br>
              <span>token launch and equity giveaways</span>
            </span>
            <Link to="/node-sale" className="home-navlink22">
              <ButtonSecondary
                label="Learn more"
                subtext="Read about node sale"
                rootClassName="button-secondaryroot-class-name3"
                className="home-component42"
              ></ButtonSecondary>
            </Link>
          </div>
        </div>
        <SubtitleProductPromo text="HOW IT WORKS"></SubtitleProductPromo>
        <Link
          to="/blog/devnet-testnet-or-mainnet-deployment"
          className="home-navlink23"
        >
          <AnnouncementSmall
            text="Devnet, Testnet or Mainnet deployment?"
            rootClassName="announcement-smallroot-class-name6"
            className="home-component44"
          ></AnnouncementSmall>
        </Link>
        <SubtextProductPromo text="An elegant architecture with 35+ micro-services built on a most efficient Ethereum client"></SubtextProductPromo>
        <div id="scheme-container" className="home-scheme-container">
          <img
            alt="image"
            src="/external/how-it-works-2300w.png"
            className="home-image2"
          />
        </div>
        <SubtitleProductPromo></SubtitleProductPromo>
        <Link to="/blog/gateway-wirex-case-study" className="home-navlink24">
          <AnnouncementSmall
            text="We enabled Wirex to elevate its zk-powered network"
            rootClassName="announcement-smallroot-class-name7"
            className="home-component47"
          ></AnnouncementSmall>
        </Link>
        <div className="home-testimonials1">
          <Testimonial
            name="Wirex"
            text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
            position="@wirexapp"
            rootClassName="testimonialroot-class-name21"
            logopolygonSrc="/external/vector1317-f6xm.svg"
          ></Testimonial>
          <Testimonial
            name="GPT Protocol"
            text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
            position="@gpt_protocol"
            rootClassName="testimonialroot-class-name22"
            logopolygonSrc="/external/logos/logo-gpt-200w.png"
          ></Testimonial>
          <Testimonial
            name="Dora"
            text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
            position="@SearchOnDora"
            rootClassName="testimonialroot-class-name23"
            logopolygonSrc="/external/logos/logo-dora1-200h.png"
          ></Testimonial>
        </div>
        <div className="home-testimonials2">
          <Testimonial rootClassName="testimonialroot-class-name18"></Testimonial>
          <Testimonial
            name="Gnosis"
            text="... affordable and highly reliable RPC hosting services ... during the Chiado testnet merge, processing the first post-merge block. We look forward to this continued"
            position="Stefan George, CTO"
            rootClassName="testimonialroot-class-name19"
            logopolygonSrc="/external/image1inch1inchlogo26986-bbaj-200h.png"
          ></Testimonial>
          <Testimonial
            name="Goldsky"
            text="...we're used to seeing nodes latency increase overtime ... Gateway's node handled the load incredibly well, to the point we thought we did something wrong :)"
            position="@jefftyling"
            rootClassName="testimonialroot-class-name20"
            logopolygonSrc="/external/logo-goldsky-200h.png"
          ></Testimonial>
        </div>
        <SubtitleProductPromo text="VISIT STAKEWAY"></SubtitleProductPromo>
        <a href="https://stakeway.com" className="home-link4">
          <AnnouncementSmall
            text="We're top 10 Lido validator"
            rootClassName="announcement-smallroot-class-name5"
            className="home-component55"
          ></AnnouncementSmall>
        </a>
        <SubtextProductPromo text="We run validators for Lido (top 10), Flare, Gnosis &amp; Namada. Bitcoin staking with Babylone coming soon"></SubtextProductPromo>
        <a href="https://stakeway.com" className="home-link5">
          <ButtonPrimarySubtitle
            main="Visit Stakeway"
            supportive="Staking for institutions"
            rootClassName="button-primary-subtitleroot-class-name4"
            className="home-component57"
          ></ButtonPrimarySubtitle>
        </a>
        <img
          alt="image"
          src="/external/stakeway-block-1200w.png"
          className="home-image3"
        />
      </div>
      <Footer rootClassName="footerroot-class-name10"></Footer>
    </div>
  )
}

export default Home
